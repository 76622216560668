/**
 * Router service file
 */
import Dashboard from "routes/Dashboard";
import Tickets from "routes/Ticket/Tickets";
import TicketsDetail from "routes/Ticket/detail"
import ImportProducts from "routes/Import_Products";
import OverviewPromoCode from "routes/promo-code/redirection";
import InternUsers from 'routes/InternUsers';
import Customers from 'routes/Customers';
export default [
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "tickets",
    component: Tickets,
  },
  {
    path: "details",
    component: TicketsDetail,
  },
  {
    path: "import",
    component: ImportProducts,
  },
  {
    path: "promocode/overview",
    component: OverviewPromoCode,
  },  
	{
		path: 'intern-users',
		component: InternUsers
	 },
   {
		path: 'customers',
		component: Customers
	 }

];
