/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';


const AsyncDashboardComponent = Loadable({
	loader: () => import("routes/Dashboard/Dash"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const InterUserDetailsComponent = Loadable({
	loader: () => import("routes/InternUsers/Details"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const UpdateInterUserComponent = Loadable({
	loader: () => import("routes/InternUsers/Update"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})


const InternUsersList = Loadable({
	loader: () => import("routes/InternUsers/users"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

const AsyncTicketComponent = Loadable({
	loader: () => import("routes/Ticket/Tickets/Tickets"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncTicketDetailComponent = Loadable({
	loader: () => import("routes/Ticket/detail/Detail"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncImportProductComponent = Loadable({
	loader: () => import("routes/Import_Products/import_product_step"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncAddPromoCodeComponent = Loadable({
	loader: () => import("routes/promo-code/redirection"),
   loading: () => <HulkPageLoader />,
   delay: 3000,
});

const AsyncCustomersComponent = Loadable({
	loader: () => import("routes/Customers/CustomersList/Customers"),
   loading: () => <HulkPageLoader />,
   delay: 3000,
});
const AsyncCustomerDetailsComponent = Loadable({
	loader: () => import("routes/Customers/CustomerDetails"),
   loading: () => <HulkPageLoader />,
   delay: 3000,
});
const AsyncCustomerDetails2Component = Loadable({
	loader: () => import("routes/Customers/CustomerDetails/customer-detail"),
   loading: () => <HulkPageLoader />,
   delay: 3000,
});



export {
	AsyncDashboardComponent,
	AsyncTicketComponent,
	AsyncTicketDetailComponent,
	AsyncImportProductComponent,
	AsyncAddPromoCodeComponent,
	InterUserDetailsComponent,
	UpdateInterUserComponent,
	InternUsersList,
	AsyncCustomersComponent,
	AsyncCustomerDetailsComponent,
	AsyncCustomerDetails2Component

	
};