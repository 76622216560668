import {
  getDocs,
  setDoc,
  collection,
  query,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { DateFormatter } from "../_helpers/DateFormatter";
export const promocodeService = {
  createCodePromo,
  deletePromoCode,
  getAllPromoCode,
};
async function createCodePromo(object) {
  if (!object || !object.id) {
    throw new Error("Object or object.id is undefined or null");
  }

  const docRef = doc(db, "xc_promo_code", object.id);
  try {
    await setDoc(docRef, {
      ...object,
      limit_users:
        object.limit_users === "" ? -1 : parseInt(object.limit_users, 10),
      expiration_date: new Date(object.expiration_date),
      creation_date: DateFormatter.initDate(),
    });
    return {
      message: "Promo code created successfully!",
    };
  } catch (error) {
    console.error("Error creating promo code:", error);
    throw error; // Rethrow the error for further handling
  }
}

async function deletePromoCode(id) {
  const docRef = doc(db, "xc_promo_code", id);
  await deleteDoc(docRef);
}
async function getAllPromoCode() {
  let userQuerry = query(collection(db, "xc_promo_code"));
  const userDoc = await getDocs(userQuerry);
  const formattedArray = await formatQuerySnapshotToArray(userDoc);
  return formattedArray;
}

const formatQuerySnapshotToArray = async (querySnapshot) => {
  const dataArray = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    dataArray.push(data);
  });

  return dataArray;
};
