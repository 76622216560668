import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import SidebarContent from './Components/SidebarContent';
import logoXciteBurger from "../../assets/Icons/black-logo.svg";
import jwt_decode from "jwt-decode";
const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: '#272e3d',
		borderRight: '0',
		overflowY: 'hidden'
	},
	drawerHeader: {
		display: 'flex',
		borderBottom: '1px solid rgba(255, 255, 255, 0.15);',
		alignItems: 'center',
		padding: theme.spacing(0, 2),
		...theme.mixins.toolbar,
		justifyContent: 'flex-start',
	},
	dFlex: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	smallBtn: {
		padding: '2px 5px',
		fontSize: '0.8rem',
	}
}));

export default function Sidebar(props) {
	const token = JSON.parse(localStorage.getItem('currentuser'));
	var decoded = jwt_decode(token);
	const classes = useStyles();
	const { closeSidebar } = props;
	const [user, setUser] = useState(decoded.user);

	return (
		<div>
			<div className="sidebar-wrap vh-150">
				<div className={classes.drawerHeader} style={{ minHeight: "200px", width: "100%" }}>
					<Box className="site-logo" display="flex" alignItems="center" justifyContent='center' flexDirection='column' width={"100%"}>
					<label className="custom-file-upload fas">
					<div className="img-wrap">
                           <img
                               alt="photo-user"
                               src={logoXciteBurger}
                            />
                    </div>
					</label>
					<div className="userNameBurgerBar">
                           {user
                            ? user?.first_name + " " + user?.last_name
                            : "User name"}
                        </div>
					</Box>
				</div>
				<Scrollbars
					className="hulk-scroll"
					autoHide
					autoHideDuration={160}
					style={{ height: 'calc(120vh - 125px' }}
				>
					<SidebarContent closeSidebar={closeSidebar}></SidebarContent>
				</Scrollbars>

			</div>
		</div>
	);
}