/**
 * Courses Routing File
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import { AsyncTicketComponent } from "routes";

const Tickets = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} component={AsyncTicketComponent}></Route>
  </Switch>
);

export default Tickets;
