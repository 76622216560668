import React from 'react';
import PromoCodeOverview from './promo-code-admin';

const Redirection = ({ match }) => {
 
  return (
    <>
      <PromoCodeOverview />
    </>
  );
};

export default Redirection;
