import React, { memo, useRef, useMemo, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import warningIcon from "../../assets/Icons/warningIcon.png";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Delete } from "@mui/icons-material";
const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    margin: "0 auto",
    width: "100%",
    height: "100%",
    backgroundColor: "#FFFFFF",

    "& .ag-row": {
      borderBottom: "1px dashed rgba(151, 151, 151, 1)",
      maxHeight: "90px",
      height: "90px",
      minHeight: "90px",
    },
    "& .ag-row-last": {
      borderBottom: "1px dashed rgba(151, 151, 151, 1)",
    },
    "& .ag-cell": {
      display: "flex",
      alignItems: "center",
      lineHeight: "20px",
      padding: "8px",
      boxSizing: "border-box",
    },
  },
}));

function PromoDataGrid(props) {
  const classes = useStyles();
  const gridRef = useRef(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);


  // Define grid options
  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        selection:true,
      },
      getRowHeight: () => 90,
    }),
    []
  );
  const stableRows = React.useMemo(
    () => props.filteredRows,
    [props.filteredRows]
  );
  const onRowSelected = (event) => {
    // Get all selected rows
    const selectedRows = gridRef.current.api.getSelectedRows();
    handleSelectionChange(selectedRows)
  };
  const handleSelectionChange = (newRowSelectionModel) => {
    //rowSelectionModel.push(newRowSelectionModel);
    setRowSelectionModel(newRowSelectionModel);
  };
  return (
    
<>
<div style={{ width: "100%" }} className="promocodeAdmin">
                      <div
                        hidden={rowSelectionModel.length > 0 ? false : true}
                        className="contain_button_dashboard"
                      >
                        <button
                          className={`button_delete_dashboard`}
                          onClick={() => props.handleDeleteOrArchived("delete")}
                        >
                          <Delete /> <span>Löschen</span>
                        </button>
                      </div>
                    </div>
    <div className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            rowData={stableRows}
            gridOptions={gridOptions}
            columnDefs={props.columns}
            domLayout="autoHeight"
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onRowSelected={onRowSelected} 
          />
        </div>
        {props.showAlert && (
                  <SweetAlert
                    custom
                    showCancel
                    showCloseButton
                    confirmBtnText={props.alertAction}
                    cancelBtnText="Abbrechen"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="light"
                    title={props.alertTitle}
                    customIcon={warningIcon}
                    onConfirm={props.handleConfirm}
                    onCancel={props.onCancel}
                  >
                    {props.alertMessage}
                  </SweetAlert>
                )}
  </>
  );
}
export default memo(PromoDataGrid);
