import { db } from "../config/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";



async function getRealEstateByCompany(company_id) {
  // Create a query for the xc_real_estate collection
  const queryRef = query(
    collection(db, "xc_real_estate"),
    where("makler_id", "==", company_id)
  );

  // Get the query snapshot
  const querySnapshot = await getDocs(queryRef);

  // Map through the documents in the snapshot and return their data
  const realEstateData = querySnapshot.docs.map(doc => ({
    id: doc.id,  // Optional: If you want to return the document ID
    ...doc.data() // Extract the document data
  }));

  return realEstateData;  // Return the array of real estate data
}


  export const realEstateService = {
    getRealEstateByCompany,
  };