/**
 * Blog Routing File
 */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  InternUsersList,
  InterUserDetailsComponent,
  UpdateInterUserComponent
} from 'routes'

const Customers = ({ match }) => (

  < Switch >
    <Route
      path={`${match.url}/details`}
      component={InterUserDetailsComponent}
    ></Route>
    <Route
      path={`${match.url}/List`}
      component={InternUsersList}
    ></Route>
    <Route
      path={`${match.url}/update`}
      component={UpdateInterUserComponent}
    ></Route>
  

  </Switch >
)
export default Customers
