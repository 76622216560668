import React, { useRef, useState ,useEffect} from "react";
import { Link, useLocation ,useHistory} from "react-router-dom";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Alert from "@mui/material/Alert";
import userIcon from "../../assets/Icons/user-icon.svg";
import passwordIcon from "../../assets/Icons/password-icon.svg";
import infoIcon from "../../assets/Icons/info-icon.svg";
import { Col, Row } from "react-bootstrap";
import logoXcite from "../../assets/Icons/black-logo.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { InputAdornment } from "@mui/material";
import {userService} from '../../_services/user.service.js';
export default function Login() {

   // State hooks for form values, validation errors, loading state, etc.
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  
  // Refs for managing focus on the email and password input fields
  const emailRef = useRef();
  const passwordRef = useRef();

  // React Router hooks for history and location
  const history = useHistory();
  const location = useLocation();
  const redirectPath = location.state?.path || '/'; // Default redirect path

  // Toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  // Prevent default behavior of mouse down event (used for password visibility toggle)
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Handle form field value changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Submit the form
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form values before submission
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) return; // If errors exist, prevent form submission

    setLoading(true);
    try {
      const data = await userService.login(formValues.email, formValues.password);
      
      // Store user token in localStorage
      localStorage.setItem('currentuser', JSON.stringify(data.token));
      
      setIsSubmit(true);
      
      // Redirect after a short delay
      setTimeout(() => {
        window.location.href = redirectPath;
      }, 1000);
    } catch (err) {
      // Set error state if login fails
      setError('Einloggen fehlgeschlagen');
    } finally {
      setLoading(false);
    }
  };

  // Validate form inputs
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Ungültige E-Mailadresse";
    } else if (!regex.test(values.email)) {
      errors.email = "Ungültige E-Mailadresse";
    }

    if (!values.password) {
      errors.password = "Ungültige Passwort";
    } else if (values.password.length < 6) {
      errors.password = "Das Passwort muss mindestens 6 Zeichen lang sein!";
    } 

    return errors;
  };

  // Open an external URL in a new window (e.g., for social login or terms link)
  const handleRedirect = (url) => {
    window.open(url, 'MyWindow', 'width=700,height=500,top=100,left=100');
  };

  // Check if the user is already logged in (on component mount)
  useEffect(() => {
    const currentUser = localStorage.getItem('currentuser');
    if (currentUser) {
      // User is logged in, redirect to the dashboard
      setLoading(true);
      history.push('/app/dashboard');
    }
  }, [history]);






  return (
    <div className="bg-color-home">
      <div className="margin-login container">
        <div className="row">
          <div className="col-md-12">
            {Object.keys(formErrors).length === 0 && isSubmit ? (
              <Alert severity="success">Ihre Anmeldung war erfolgreich.</Alert>
            ) : (
              error && <Alert severity="error">{error}</Alert>
            )}
            <div className="centred-title pb-3 pt-3 ">
              <h1 className="title-login">Einloggen</h1>
            </div>
            <div className="card pt-3 pb-3">
              <div className="card-contain padding">
                <span className="logo">
                  <img src={logoXcite} alt="logo" />
                </span>
                <Row className="mt-3 mb-3">
                  <Col md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <div className="input-field">
                        <img
                          className="userIcon"
                          src={userIcon}
                          alt="user Icon"
                        />
                        <TextField
                        name="email"
                        value={formValues.email}
                        id="email"
                        type="email"
                        inputRef={emailRef}
                        label="LOGIN"
                        placeholder="LOGIN *"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={handleInputChange}
                        error={error}
                        required
                        InputProps={{
                          style: { paddingLeft: '35px' },
                        }}
                        variant="outlined" />
                      </div>
                      <span className="error">{formErrors.email}</span>

                      <div className="input-field">
                        <img
                          className="passwordIcon"
                          src={passwordIcon}
                          alt="user Icon"
                        />
                        <TextField
                        name="password"
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        inputRef={passwordRef}
                        label="PASSWORT"
                        placeholder="PASSWORT *"
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        error={error}
                        value={formValues.password}
                        InputProps={{
                          style: { paddingLeft: '35px' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleTogglePasswordVisibility}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        variant="outlined"
                        className="input-field-PP"
                      />
                        <span className="error">{formErrors.password}</span>
                      </div>
                    </Box>
                  </Col>

                  <Col md={12} className="mt-2 mb-1">
                    <div className="Einloggen">
                      <button
                        disabled={loading}
                        className="LoginButton"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Einloggen
                        <ArrowForwardIosIcon
                          className="icon-registrieren"
                          sx={{ fontSize: 20 }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col md={12} className="mt-1 mb-1">
                    <div className="LoginAbout">
                      <img
                        className="infoIcon"
                        src={infoIcon}
                        alt="user Icon"
                      />
                      <Link
                        to="#"
                        onClick={() => handleRedirect("https://x-cite-app.de/")}
                      >
                        Erfahren Sie mehr über die X-CITE.immo App.
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
