/**
 * Courses Routing File
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import { AsyncTicketDetailComponent } from "routes";

const TicketsDetail = ({ match }) => {
    return(
        <Switch>
        <Route
        path={`${match.url}/:id`}
        component={AsyncTicketDetailComponent}
      ></Route>
      </Switch>
    );
} 

export default TicketsDetail;
