/**
 * Courses Routing File
 */

import { StatusProvider } from 'context/StatusContext';
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
    AsyncImportProductComponent
} from 'routes';

const ImportProducts = ({ match }) => (
	<Switch>
			<StatusProvider>
        <Route path='/app/import' component={AsyncImportProductComponent} ></Route>		
		</StatusProvider>

	</Switch>
)
export default ImportProducts;