/**
 * Courses Routing File
 */


import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import {
	
	AsyncDashboardComponent
} from 'routes';

const Dashboard = ({ match }) => (
	<Switch>
			<FirebaseContextProvider>
				<Route path={`${match.url}`} component={AsyncDashboardComponent}></Route>
				</FirebaseContextProvider>
	
	</Switch>
)
export default Dashboard;