import React, { createContext } from "react";
export const FirebaseContext = createContext();

const FirebaseContextProvider = ({ children }) => {
 

  return (
    <FirebaseContext.Provider>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContextProvider };
