/**
 * Courses Routing File
 */


import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import {

	AsyncCustomersComponent,
	AsyncCustomerDetailsComponent,
	AsyncCustomerDetails2Component
} from 'routes';

const Customers = ({ match }) => (
	<Switch>
		<FirebaseContextProvider>
			<Route
				path={`${match.url}/details`}
				component={AsyncCustomerDetailsComponent}
			></Route>
			<Route
				path={`${match.url}/customer-details`}
				component={AsyncCustomerDetails2Component}
			></Route>
			<Route
				path={`${match.url}/List`}
				component={AsyncCustomersComponent}
			></Route>
		</FirebaseContextProvider>

	</Switch>
)
export default Customers;

